import { useButton } from "@react-aria/button";
import { useHover } from "@react-aria/interactions";
import { FocusRing } from "@react-aria/focus";
import React, { useRef } from "react";
import { mergeProps } from "@react-aria/utils";

function Button(props) {
  let domRef = useRef();
  let {
    elementType: ElementType = "button",
    children,
    variant,
    isQuiet,
    isDisabled,
    autoFocus,
    altTag,
    ...otherProps
  } = props;
  let { buttonProps } = useButton(props, domRef);
  let { hoverProps, isHovered } = useHover({ isDisabled });

  const classes = {
    secondary:
      "border-pink-500 h-12 border-2 px-4 hover:bg-pink-500 text-pink-500 focus:ring-2 focus:ring-offset-1 rounded-lg font-bold hover:text-white",
  };

  return (
    <FocusRing focusRingClass="ring-2">
      <ElementType
        className={
          variant === "secondary"
            ? classes.secondary
            : "bg-pink-500 border-2 border-pink-500 h-12 px-4 hover:bg-pink-300 focus:ring-2 focus:ring-offset-1 rounded-lg font-bold text-white"
        }
        // {...styleProps}
        {...mergeProps(buttonProps, hoverProps)}
        ref={domRef}
      >
        {children}
      </ElementType>
    </FocusRing>
  );
}

export default Button;
