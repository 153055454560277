import React from "react";
import { PageNavbar } from "components/layout/PageNavbar";
import { PageFooter } from "components/layout/PageFooter";
import { ProgressIndicator } from "components/block/ProgressIndicator";

export function PageLayout({ children }) {
  return (
    <>
      <PageNavbar></PageNavbar>
      <ProgressIndicator />
      <main className="flex-col flex sm:max-w-2xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl max-w-6xl mx-8 sm:mx-auto">
        {children}
      </main>
      <PageFooter> </PageFooter>
    </>
  );
}
