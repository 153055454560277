import React from "react";
import Button from "components/atom/Button";
import "./style.css";

export function PageFooter() {
  return (
    <footer>
      <div className="page-footer__container">
        <div className="page-footer__text">
          Let's work together on your next project
        </div>
        <Button
          onClick={() =>
            (window.location = "https://www.linkedin.com/in/kerriehui/")
          }
        >
          Get in touch
        </Button>
        <div className="page-footer__copyright">
          © Kerrie Hui {new Date().getFullYear()}
        </div>
      </div>
    </footer>
  );
}
