import React, { useEffect, useState } from "react";
import "./style.css";

const ProgressIndicator = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const calculateProgress = () => {
      const windowScroll =
        document.body.scrollTop || document.documentElement.scrollTop;
      const height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const scrolled = windowScroll / height;
      setProgress(scrolled);
    };

    window.addEventListener("scroll", calculateProgress);

    return () => window.removeEventListener("scroll", calculateProgress);
  }, [progress]);

  return (
    <div className="progress w-full fixed top-0 z-50">
      <div
        className="progress-bar"
        style={{ transform: `scaleX(${progress})` }}
      ></div>
    </div>
  );
};

export { ProgressIndicator };
