import React from "react";
import "./style.css";
import Logo from "images/logo.svg";
import LogoutButton from "components/atom/LogoutButton";

export function PageNavbar() {
  return (
    <nav className="page-navbar__fixed">
      <div className="page-navbar__container">
        <div className="page-navbar__content-container">
          <div className="page-navbar__content-container--left">
            <div className="page-navbar__name">Kerrie Hui</div>
            <h2 className="page-navbar__name-description">
              Sydney-based Product
              <br />& UX Designer
            </h2>
          </div>
          <div className="page-navbar__content-container--right">
            <div className="page-navbar__logo-container">
              <img
                className="block lg:hidden h-12 w-auto"
                src={Logo}
                alt="Kerrie Hui Logo"
              />
              <img
                className="hidden lg:block h-12 w-auto"
                src={Logo}
                alt="Kerrie Hui Logo"
              />
            </div>
            <LogoutButton />
          </div>
        </div>
      </div>
    </nav>
  );
}
